import { mapPxToRem, pxToRem } from "themes/utils/mapPxToRem";

const largeSizes = {
    wfull: "100vw",
    hfull: "100vh",
    max: "max-content",
    min: "min-content",
    full: "100%",
    "3xs": "14rem",
    "2xs": "16rem",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "7xl": "80rem",
    "8xl": "90rem",
    headerMobileTopHeight: pxToRem("100px"),
    headerTopHeight: pxToRem("120px"),
    carouselPaginationTopMargin: pxToRem("60px"),
    carouselNavigationButtonSize: pxToRem("56px"),
    // anchorNavMobileHeight: pxToRem("60px"),
    // anchorNavDesktopHeight: pxToRem("130px"),
};

const container = mapPxToRem({
    xs: "480px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1440px",
    image: "1120px",
});

const sizes = {
    ...largeSizes,
    container,
};

export default sizes;
