import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const $height = cssVar("input-height");
const $fontSize = cssVar("input-font-size");
const $padding = cssVar("input-padding");
const $borderRadius = cssVar("input-border-radius");

const baseStyle = definePartsStyle({
    addon: {
        height: $height.reference,
        fontSize: $fontSize.reference,
        px: $padding.reference,
        borderRadius: $borderRadius.reference,
    },
    field: {
        width: "100%",
        height: $height.reference,
        fontSize: $fontSize.reference,
        px: $padding.reference,
        borderRadius: $borderRadius.reference,
        minWidth: 0,
        outline: 0,
        position: "relative",
        appearance: "none",
        transitionProperty: "common",
        transitionDuration: "normal",
        _disabled: {
            opacity: 0.4,
            cursor: "not-allowed",
        },
    },
});

const size = {
    lg: defineStyle({
        [$fontSize.variable]: "fontSizes.xs",
        [$padding.variable]: "space.4",
        [$height.variable]: "sizes.12",
    }),
};

const sizes = {
    default: definePartsStyle({
        field: size.lg,
        group: size.lg,
    }),
};

const variantOutline = definePartsStyle(() => {
    return {
        field: {
            border: "1px solid",
            borderColor: "inherit",
            bg: "inherit",
            height: "40px",
            _readOnly: {
                boxShadow: "none !important",
                userSelect: "all",
            },
            _invalid: {
                borderColor: "brand.red",
            },
            _focusVisible: {
                zIndex: 1,
                // borderColor: ,
                // boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
            },
        },
    };
});

const variantUnstyled = definePartsStyle({
    field: {
        bg: "transparent",
        px: "0",
        height: "auto",
    },
    addon: {
        bg: "transparent",
        px: "0",
        height: "auto",
    },
});

const variants = {
    outline: variantOutline,
    unstyled: variantUnstyled,
};

const Input = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "default",
        variant: "outline",
    },
});

export default Input;
