import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import animations from "themes/foundations/animations";
import { pxToRem } from "themes/utils/mapPxToRem";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    accordionAnatomy.keys
);

const brandYellow = "brand.yellow";

const baseStyle = definePartsStyle({
    root: { borderTop: "1px solid", borderColor: "brand.lightGray" },
    container: {},
    button: {
        textAlign: "left",
        gap: ["sp12", null, null, "sp24"],
        alignItems: ["top", null, null, null, "center"],
        fontSize: ["sm", null, null, "xl", null],
        px: "sp24",
        py: "sp32",
        _hover: { background: brandYellow },
        _focusVisible: { background: brandYellow },
        _expanded: { background: brandYellow },
        transitionDuration: animations.fast,
    },
    panel: {
        px: ["sp60", null, null, "sp216"],
        py: "sp32",
        pr: [null, null, null, "sp96", "sp96"],
    },
    icon: {
        minWidth: pxToRem("24px"),
        minHeight: pxToRem("24px"),
    },
});

const variants = {
    SessionAccordion: {
        panel: {
            px: "sp24",
            py: "sp32",
        },
    },
};

export default defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: {},
});
