import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/styled-system";
import typography from "themes/foundations/typography";

const baseStyle = defineStyle({
    lineHeight: "none",
    fontWeight: "medium",
    letterSpacing: "wide",
    display: "inline-block",
    textUnderlineOffset: "2px",

    _focusVisible: {
        outline: "1px dashed currentColor",
        outlineOffset: 2,
        outlineColor: "currentColor",
        transition: "all 0.1s",
    },
});

const sizes: Record<string, SystemStyleObject> = {
    sm: {
        fontSize: "3xs",
    },
    "2xs": {
        fontSize: "2xs",
    },
};

const underlineEffect = {
    content: "''",
    bg: "white",
    position: "absolute",
    bottom: -2,
    height: 1,
    left: 0,
    right: "100%",
    transition: "all ease 0.2s",
};

const variants = {
    default: {},
    uppercase: {
        textTransform: "uppercase",
    },
    footerGroupLink: {
        display: "inline-flex",
        alignItems: "center",
        fontSize: "xs",
        fontWeight: typography.fontWeights.light,
        letterSpacing: typography.letterSpacings.tight,
        lineHeight: typography.lineHeights.tall,
        position: "relative",
        _before: {
            ...underlineEffect,
        },
        _focusVisible: {
            _before: {
                right: 0,
            },
        },
        _hover: {
            _before: {
                right: 0,
            },
        },
    },
    footerDisclaimerLink: {
        textTransform: "uppercase",
        fontSize: "3xs",
        lineHeight: "short",
        letterSpacing: "wide",
        width: "100%",
        textAlign: "left",
        position: "relative",
        _before: {
            ...underlineEffect,
            bottom: -3,
        },
        _focusVisible: {
            _before: {
                right: 0,
            },
        },
        _hover: {
            _before: {
                right: 0,
            },
        },
    },
    underlineLink: {
        position: "relative",
        _before: {
            ...underlineEffect,
            bg: "currentColor",
            right: 0,
        },
        _focusVisible: {
            _before: {
                right: "100%",
            },
        },
        _hover: {
            _before: {
                right: "100%",
            },
        },
    },
    underlineHoverLink: {
        position: "relative",
        _before: {
            ...underlineEffect,
            bg: "currentColor",
            right: "100%",
        },
        _focusVisible: {
            _before: {
                right: 0,
            },
        },
        _hover: {
            _before: {
                right: 0,
            },
        },
    },
    searchLink: {
        _hover: {
            opacity: 0.5,
        },
        _focusVisible: {
            opacity: 0.5,
            outline: "1px dotted currentColor",
            outlineOffset: 2,
            transition: "all 0.1s",
        },
    },
    clearAllLink: {
        fontSize: "2xs",
        letterSpacing: typography.letterSpacings.tight,
        lineHeight: typography.lineHeights.tall,
        textTransform: "uppercase",
        textDecoration: "underline",

        _hover: {
            textDecoration: "none",
        },
    },
};

export default defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "xs",
        variant: "default",
    },
});
