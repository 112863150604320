import heading from "./components/heading";
import text from "./components/text";
import { gradients } from "./foundations/color";
import { mapGradient } from "./utils/mapGradient";

const globalStyle = {
    global: () => ({
        html: {
            scrollPaddingTop: [
                "calc(var(--sizes-headerMobileTopHeight) + 70px)",
                null,
                "calc(var(--sizes-headerTopHeight) + 70px)",
            ],
        },
        body: {
            fontFamily: "body",
            color: "black",
            bg: "white",
        },
        svg: {
            width: "1rem",
            height: "1rem",
        },
        ".richtext": {
            "h1,h2,h3,h4,h5,h6,blockquote,p,ul,ol": {
                ":first-child": {
                    mt: "0",
                },
            },
            "h1,h2": {
                ...heading.sizes?.h2,
                mt: "sp128",
                mb: "sp56",
            },
            h3: {
                ...heading.sizes?.h3,
                mt: "sp36",
                mb: "sp24",
            },
            h4: {
                ...heading.sizes?.h5,
                fontSize: ["md", null, null, "mlg"],
                mt: "sp36",
                mb: "sp20",
            },
            h5: {
                ...heading.sizes?.h5,
                mt: "sp36",
                mb: "sp16",
            },
            "h6, blockquote": {
                ...heading.sizes?.h5,
                my: "sp40",
                _before: {
                    content: `'“'`,
                },
                _after: {
                    content: `'”'`,
                },
                bgGradient: mapGradient(gradients.purpleToOrange),
                bgClip: "text",
                color: "transparent",
            },
            p: {
                ...text.sizes?.bodySmall,
                marginBottom: "1rem",
                strong: {
                    fontWeight: "700",
                },
                em: {
                    fontStyle: "italic",
                },
            },
            a: {
                textDecoration: "underline",
                cursor: "pointer",
                outline: "none",
                wordBreak: "break-word",
                fontWeight: "medium",
                _focusVisible: {
                    outline: "1px dotted black",
                    outlineOffset: "2px",
                    outlineColor: "black",
                    textDecoration: "underline dotted",
                },
                _hover: {
                    outline: "none",
                    textDecoration: "none",
                },
            },
            "ul, ol": {
                ml: "18",
                ...text.sizes?.bodySmall,
                "> li": {
                    ml: "sp8",
                    lineHeight: "tall",
                    mb: "1em",
                    strong: {
                        fontWeight: "700",
                    },
                    em: {
                        fontStyle: "italic",
                    },
                },
            },
            strong: {
                fontWeight: "normal",
            },
            em: {
                fontStyle: "normal",
            },
        },
    }),
};

export default globalStyle;
