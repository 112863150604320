import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    width: "100%",
    maxWidth: "container.xxl",
    margin: "0 auto",
    px: "clamp(1.5rem, -1.493rem + 12.77vw, 10rem)",
});

const sizes: Record<string, SystemStyleObject> = {};

const variants = {
    header: {
        px: ["sp28", null, null, "sp40", "sp80"],
    },
    hero: {
        px: ["sp24", null, null, "sp40", "sp80"],
    },
    module: {
        py: ["sp60", null, null, "sp96"],
    },
    fullWidthHero: {
        px: 0,
    },
};

export default defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "md",
    },
});
