import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    fontFamily: "body",
    fontWeight: "normal",
});

const sizes: Record<string, SystemStyleObject> = {
    h1: {
        fontSize: ["4xl"],
        fontWeight: "light",
        lineHeight: "none",
    },
    h2: {
        fontSize: ["2xl"],
        fontWeight: "light",
        lineHeight: "shorter",
    },
    eyebrow: {
        fontSize: ["sm"],
        fontWeight: "bold",
        lineHeight: "short",
    },
    eyebrowSmall: {
        fontSize: ["2xs"],
        textTransform: "uppercase",
        fontWeight: "bold",
        lineHeight: "short",
    },
    h3: {
        fontSize: ["md", null, null, "xl"],
        fontWeight: "light",
        lineHeight: "shorter",
    },
    h4: {
        fontSize: ["lg"],
        fontWeight: "normal",
        lineHeight: "shorter",
    },
    h5: {
        fontSize: ["sm", null, null, "md"],
        fontWeight: "normal",
        lineHeight: "shorter",
    },
    body: {
        fontSize: ["xs", null, null, "sm"],
        fontWeight: "normal",
        lineHeight: "shorter",
    },
    bodySmall: {
        fontSize: "xs",
        fontWeight: "normal",
        lineHeight: "tall",
    },

    bodyLarge: {
        fontSize: "sm",
        fontWeight: "normal",
        lineHeight: "shorter",
    },
    bodyXl: {
        fontSize: "md",
        fontWeight: "normal",
        lineHeight: "shorter",
    },
    articleDate: {
        fontSize: "2xs",
        textTransform: "uppercase",
        letterSpacing: "tight",
    },
    quote: {
        fontSize: ["md", null, null, "xl"],
        fontWeight: "normal",
        lineHeight: "short",
    },
    copyright: {
        fontSize: "3xs",
        fontWeight: "normal",
        lineHeight: "short",
        textTransform: "uppercase",
        letterSpacing: "wide",
    },
    anchorDownloadHeadline: {
        fontSize: "3xs",
        fontWeight: "bold",
        letterSpacing: "tight",
        lineHeight: "shorter",
    },
    openPositionsLabel: {
        fontSize: "1em",
    },
    imageCaptionHeadline: {
        fontSize: "xs",
        fontWeight: "bold",
    },
    heroProgressBarText: {
        fontSize: ["xxs", null, null, "xs"],
    },
    "2xs": {
        fontSize: "2xs",
    },
    "3xs": {
        fontSize: "3xs",
        lineHeight: "shorter",
    },
    xs: {
        fontSize: "xs",
    },
    xl: {
        fontSize: "xl",
    },
    md: {
        fontSize: { base: "md", lg: "mlg" },
        lineHeight: 1.2,
    },
    "4xl": {
        fontSize: { base: "4xl", lg: "5xl" },
    },
};

const variants = {
    default: {},
    moduleHeaderText: {
        lineHeight: "normal",
        fontWeight: "light",
    },
    bold: {
        fontWeight: "bold",
    },
    articleCardHeader: {
        lineHeight: "short",
        textTransform: "uppercase",
    },
    link: {
        fontSize: "2xs",
        fontWeight: "medium",
        letterSpacing: "wide",
        color: "black",
        position: "relative",
        _before: {
            content: "''",
            bg: "black",
            position: "absolute",
            bottom: -2,
            height: 1,
            left: 0,
            right: 0,
            transition: "all ease 0.2s",
        },
    },
};

export default defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "xs",
        variant: "default",
    },
});
