import { transparentize } from "polished";

const colors = {
    white: "#FFFFFF",
    black: "#000000",
    brand: {
        lighterGray: "#F6F6F6",
        lightGray: "#E3E3E3",
        yellow: "#F0CF61",
        orange: "#FB9B2A",
        darkOrange: "#EF5B09",
        red: "#EF3E4A",
        purple: "#4B17B6",
        darkBlue: "#00062b",
        mediumBlue: "#083EA7",
        blue: "#0A7BD7",
        darkAqua: "#0BBCD6",
        aqua: "#77EEDF",
    },
};

export const gradients = {
    aquaToRed: {
        dir: "to-tr",
        colors: [
            colors.brand.darkAqua,
            colors.brand.blue,
            colors.brand.mediumBlue,
            colors.brand.darkBlue,
            colors.brand.purple,
            colors.brand.red,
        ],
    },
    purpleToOrange: {
        dir: "to-r",
        colors: [colors.brand.purple, colors.brand.darkOrange],
    },
    spectrum: {
        dir: "to-tr",
        colors: [
            `${colors.brand.lightGray} 0%`,
            `${colors.brand.aqua} 7%`,
            `${colors.brand.darkAqua} 12%`,
            `${colors.brand.blue} 23%`,
            `${colors.brand.mediumBlue} 31%`,
            `${colors.brand.darkBlue} 50%`,
            `${colors.brand.purple} 60%`,
            `${colors.brand.red} 77%`,
            `${colors.brand.darkOrange} 82%`,
            `${colors.brand.orange} 88%`,
            `${colors.brand.lightGray} 100%`,
        ],
    },
    progress: {
        dir: "to-tr",
        colors: [
            `${colors.brand.darkAqua}`,
            `${colors.brand.blue}`,
            `${colors.brand.mediumBlue}`,
            `${colors.brand.purple}`,
            `${colors.brand.red}`,
            `${colors.brand.darkOrange}`,
            `${colors.brand.orange}`,
        ],
    },
    quoteGradient: {
        dir: "to right",
        colors: [`${colors.brand.purple} 50%`, `${colors.brand.darkOrange} 100%`],
    },
    shadowGradient: {
        dir: "to bottom",
        colors: [
            `${transparentize(1, colors.black)} 26.14%`,
            `${transparentize(0.6, colors.black)} 98.85%`,
        ],
    },
    heroCarouselGradient: {
        dir: "0",
        colors: [
            `${transparentize(1, colors.black)} 0%`,
            `${transparentize(0.7, colors.black)} 16.56%`,
        ],
    },
};

export default colors;
