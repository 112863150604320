import { mapPxToRem } from "themes/utils/mapPxToRem";

const spacings: Record<string, string> = {
    sp4: "4px",
    sp8: "8px",
    sp12: "12px",
    sp16: "16px",
    sp20: "20px",
    sp24: "24px",
    sp28: "28px",
    sp32: "32px",
    sp36: "36px",
    sp40: "40px",
    sp42: "42px",
    sp48: "48px",
    sp50: "50px",
    sp56: "56px",
    sp60: "60px",
    sp64: "64px",
    sp68: "68px",
    sp72: "72px",
    sp80: "80px",
    sp88: "88px",
    sp96: "96px",
    sp100: "100px",
    sp120: "120px",
    sp128: "128px",
    sp148: "148px",
    sp160: "160px",
    sp168: "168px",
    sp180: "180px",
    sp204: "204px",
    sp216: "216px",
    sp380: "380px",
    sp448: "448px",
};
export const spacing = mapPxToRem(spacings);
