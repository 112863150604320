import { mapPxToRem } from "themes/utils/mapPxToRem";

const breakpoints = mapPxToRem({
    sm: "481px",
    md: "821px",
    lg: "993px",
    xl: "1281px",
    xxl: "1440px",
});
export default breakpoints;
