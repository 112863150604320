import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/styled-system";

const blackColor = "black";
const borderStyle = `1px solid ${blackColor}`;

const baseStyle = defineStyle({
    lineHeight: "none",
    fontWeight: "medium",
    textTransform: "uppercase",
    letterSpacing: "wide",
    transition: "all 0.3s",
    whiteSpace: "initial",
    _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
        boxShadow: "none",
    },
    _hover: {
        _disabled: {
            bg: "initial",
        },
    },
    _focusVisible: {
        outline: "1px dashed currentColor",
        outlineOffset: "2px",
        transition: "all 0.1s",
    },
});

const sizes: Record<string, SystemStyleObject> = {
    xs: {
        fontSize: "2xs",
        py: "12",
        px: "16",
        svg: {
            width: 12,
            height: 12,
        },
        ".chakra-button__icon": {
            ml: "sp8",
        },
    },
    sm: {
        fontSize: "2xs",
        py: "12",
        px: "16",
        svg: {
            width: 16,
            height: 16,
        },
        ".chakra-button__icon": {
            ml: "sp24",
        },
    },
    md: {
        fontSize: "xs",
        py: "16",
        px: "32",
        svg: {
            width: 24,
            height: 24,
        },
        ".chakra-button__icon": {
            ml: "sp32",
        },
    },
};

const variants = {
    primary: {
        color: "white",
        bg: "black",
        fill: "white",
        border: borderStyle,
        borderRadius: "base",
        _hover: {
            bg: "white",
            color: "black",
            fill: "black",
        },
        _focusVisible: {
            bg: "white",
            color: "black",
            fill: "black",
        },
    },
    secondary: {
        color: "black",
        bg: "white",
        border: borderStyle,
        borderColor: "white",
        fill: "black",
        borderRadius: "base",
        _hover: {
            bg: "black",
            color: "white",
            fill: "white",
        },
        _focusVisible: {
            bg: "black",
            color: "white",
            fill: "white",
        },
    },
    outline: {
        color: "black",
        bg: "transparent",
        border: borderStyle,
        fill: "black",
        borderRadius: "base",
        _hover: {
            bg: "black",
            color: "white",
            fill: "white",
        },
    },
    unstyled: {
        bg: "transparent",
        p: 0,
        textTransform: "none",
        ".chakra-button__icon": {
            ml: "sp8",
        },
    },
    unstyledWithHover: {
        bg: "transparent",
        p: 0,
        textTransform: "none",
        ".chakra-button__icon": {
            ml: "sp8",
        },
        _hover: { ">span": { textDecoration: "underline" } },
    },
    sliderDot: {
        borderRadius: "100%",
        aspectRatio: "1",
        width: "8px",
        height: "8px",
        p: "0",
    },
    heroProgressBar: {
        textAlign: "left",
        textTransform: "uppercase",
        fontWeight: "bold",
        width: "100%",
        display: "block",
        p: "0",
    },
    carouselNavButton: {
        bg: "transparent",
        p: 0,
        _focusVisible: {
            bg: "red",
        },
    },
    searchResult: {
        borderBottom: "1px solid black",
        borderRadius: "none",
        fontWeight: "normal",
        fontSize: "2xs",
        _hover: {
            bg: "brand.lightGray",
        },
    },
    tab: {
        transition: "none",
        px: "sp32",
        mb: "-1px",
        "&[aria-selected=true]": {
            border: "1px solid black",
            borderBottomColor: "white",
        },
    },
};

export default defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
