/**
 * Default Chakra themes for Change lingerie.
 * https://chakra-ui.com/docs/styled-system/customize-theme
 */
import breakpoints from "./foundations/breakpoints";
import colors from "./foundations/color";
import radius from "./foundations/radius";
import shadows from "./foundations/shadow";
import sizes from "./foundations/sizes";
import { spacing } from "./foundations/spacings";
import transition from "./foundations/transition";
import typography from "./foundations/typography";
import zIndices from "./foundations/z-index";

import Button from "./components/button";
import Heading from "./components/heading";
import Text from "./components/text";
import Link from "./components/link";
import Container from "./components/container";
import Icon from "./components/icon";
import Input from "./components/input";
import List from "./components/list";
import Accordion from "./components/accordion";

import globalStyle from "./globalStyles";

const foundations = {
    breakpoints,
    colors,
    radii: radius,
    shadows,
    sizes,
    space: spacing,
    transition,
    ...typography,
    zIndices,
};

const components = {
    Button,
    Heading,
    Text,
    Link,
    Container,
    Icon,
    Input,
    List,
    Accordion,
};

const defaultTheme = {
    ...foundations,
    components,
    styles: globalStyle,
};

export default defaultTheme;
