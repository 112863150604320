import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    fontFamily: "heading",
    fontWeight: "normal",
});

const sizes: Record<string, SystemStyleObject> = {
    h1: {
        fontSize: "clamp(1rem, 7.5cqi, 4.25rem)",
        fontWeight: "light",
        lineHeight: "none",
        textTransform: "uppercase",
        letterSpacing: "tight",
    },
    h2: {
        fontSize: ["2xl"],
        fontWeight: "light",
        lineHeight: "shorter",
    },
    eyebrow: {
        fontSize: ["sm"],
        fontWeight: "bold",
        lineHeight: "short",
    },
    h3: {
        fontSize: ["md", null, null, "xl"],
        fontWeight: "light",
        lineHeight: "shorter",
    },
    h4: {
        fontSize: ["md", null, null, "lg"],
        fontWeight: "normal",
        lineHeight: "shorter",
    },
    h5: {
        fontSize: ["sm", null, null, "md"],
        fontWeight: "normal",
        lineHeight: "shorter",
    },
    footerGroupTitle: {
        fontSize: "xxs",
        fontWeight: "bold",
        lineHeight: "shorter",
    },
    lgTitle: {
        fontSize: ["xl", null, null, "5xl"],
    },

    xlTitle: {
        fontSize: ["2xl", "4xl", null, "5xl", "7xl"],
    },
    "2xs": {
        fontSize: "2xs",
    },
    xxs: {
        fontSize: "xxs",
    },
    xs: {
        fontSize: "xs",
    },
    xsm: {
        fontSize: "sm",
    },
    sm: {
        fontSize: "md",
    },
    md: {
        fontSize: ["md", null, null, "lg"],
        lineHeight: "shorter",
    },
    lg: {
        fontSize: "xl",
    },
    xl: {
        fontSize: "2xl",
        lineHeight: "shorter",
        letterSpacing: "tight",
        fontWeight: "light",
    },
    "2xl": {
        fontSize: "2xl",
    },

    "4xl": {
        fontSize: "4xl",
    },
    "6xl": {
        fontSize: "6xl",
    },
    "5xl": {
        fontSize: ["2xl", "3xl", null, "5xl"],
    },
    digitsAndDataSize: {
        fontSize: ["4xl", null, null, "5xl"],
    },
};

const variants = {
    default: {},
    moduleHeaderTitle: {
        textTransform: "uppercase",
        textAlign: "center",
        lineHeight: "none",
        fontWeight: "light",
    },
    bold: {
        fontWeight: "bold",
    },
    uppercase: {
        textTransform: "uppercase",
    },
    digitsAndDataInfo: {
        textTransform: "uppercase",
        lineHeight: "none",
        fontWeight: "light",
        letterSpacing: "tight",
    },
};

export default defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "md",
        variant: "default",
    },
});
