import { keyframes } from "@emotion/react";

export const animateIn = keyframes`
    from {
		transform: translateY(-1.4em);
        opacity: 0.5;
    }
    to {
		transform: translateY(0);
		opacity: 1;
    }
`;

export const pulseLoadingAnimation = keyframes`
	0%, 100% {
		opacity: 1;
	}
	50% {
		opacity: .3;
	}
`;

export const translateXin = keyframes`
	from {
		tranform: translateX(0);
	}
	to {
		transform: translateX(100%);
	}
`;

export const translateHeadingIn = keyframes`
	from {
		tranform: translateY(-1.1em);
	}
	to {
		transform: translateY(0);
	}
`;

const animations = {
    fast: "150ms",
    base: "300ms",
    slow: "500ms",
};

export default animations;
