import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import type { SystemStyleObject } from "@chakra-ui/styled-system";
import { pxToRem } from "../utils/mapPxToRem";

const baseStyle = defineStyle({
    fill: "currentColor",
});

const sizes: Record<string, SystemStyleObject> = {
    sm: {
        boxSize: pxToRem("16px"),
    },
    md: {
        boxSize: pxToRem("22px"),
    },
    lg: {
        boxSize: pxToRem("32px"),
    },
};

export default defineStyleConfig({
    baseStyle,
    sizes,
    defaultProps: {
        size: "sm",
    },
});
