import { GTAmerica } from "themes/fonts";
import { mapPxToRem } from "themes/utils/mapPxToRem";

const typography = {
    letterSpacings: {
        tighter: "-0.05em",
        tight: "-0.025em",
        normal: "0",
        wide: "0.025em",
        wider: "0.05em",
        widest: "0.1em",
    },

    lineHeights: {
        small: 0.8,
        normal: "normal",
        none: 1,
        shorter: 1.2,
        short: 1.34,
        base: 1.4,
        tall: 1.5,
        taller: 2,
    },

    fontWeights: {
        light: 300,
        normal: 400,
        medium: 500,
        bold: 700,
    },

    fonts: {
        heading: `${GTAmerica.style.fontFamily}, "helvetica", "arial", "sans-serif"`,
        body: `${GTAmerica.style.fontFamily}, "helvetica", "arial", "sans-serif"`,
    },

    fontSizes: mapPxToRem({
        "3xs": "11px",
        "2xs": "12px",
        xxs: "14px",
        xs: "16px",
        sm: "18px",
        md: "22px",
        lmd: "28px",
        mlg: "32px",
        lg: "36px",
        xl: "42px",
        "2xl": "50px",
        "3xl": "58px",
        "4xl": "68px",
        "5xl": "144px",
        "6xl": "160px",
        "7xl": "178px",
    }),
};

export default typography;
